import trackLink from '@99designs/common/utils/platform/trackLink';

(function ($) {
    const productCTA = $('[data-product-cta-interjection]');

    productCTA.each(function (index) {
        trackLink(this, 'Clicked CTA on Blog Post', {
            index: index,
            type: this.getAttribute('data-product-cta-interjection-type'),
        });
    });
})(jQuery);
